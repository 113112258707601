@tailwind base;
@tailwind components;
@tailwind utilities;

@view-transition {
  navigation: auto;
}

a {
  @apply font-sans text-base underline;
}

a:hover {
  @apply text-secondary;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-heading font-900 uppercase;
}

h1 {
  @apply text-3xl md:text-4xl uppercase;
}

h2 {
  @apply text-2xl md:text-3xl;
}

h3 {
  @apply text-xl md:text-2xl;
}

h4 {
  @apply text-lg md:text-xl font-800;
}

h5 {
  @apply normal-case font-800 text-base md:text-lg;
}

h6 {
  @apply normal-case font-800 text-sm md:text-base;
}

p,
li,
pre {
  @apply font-sans text-base leading-6 text-balance tracking-tight;
}

.scrollbar *::-webkit-scrollbar-thumb {
  cursor: pointer;
  @apply transition ease-in-out text-primary bg-primary;
}

.scrollbar *::-webkit-scrollbar {
  cursor: pointer;
  @apply border border-solid border-primary bg-transparent;
}
